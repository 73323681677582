import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import Body from '../components/body_search'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}


const BlogPage = (props) => {

	//decodeURI(props.location.search.split("=")[1]).replace(/\+/g, " ")
	//console.log(props.location)
	const rquery = props.location.search.includes("=") ? props.location.search.split("=")[1] : props.location.search.replace("?", "")
	const rslug = decodeURI(rquery).replace(/\+/g, " ").split(" ")
	const _scat = rslug.join("-")
	const _spost = ""
	const _npost = ""
	const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src
	props.location.host = "infonutrientes.com.br"

	return (	
		<ThemeProvider theme={theme}>

			<>
				<SEO
					title={"INFO Nutrientes - tudo sobre a composição dos alimentos"}
					description={`${rslug.join(" ")}, tudo sobre a composição nutricional dos alimentos, calorias, carboidratos, proteínas, gorduras, vitaminas e sais minerais`}
					keywords={"composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais"}
					canonical={`https://${props.location.host}${props.location.pathname}`}
					base_url={`https://${props.location.host}`}
					post_date={"2021-03-15"}
					post_cat={`${_scat}`}
					post_cat_name={`${_scat.capitalize()}`}
					post_slug={`${_spost}`}
					post_slug_name={`${_npost}`}
					post_descp_kps={`${rslug.join(" ")}, composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais`}
					nutrinfo={false}
					location={props.location}
					image={imgSrc}
				/>
				</>

			<>
				<Nav />
			</>

			<main>
				<Body {...props} />
			</main>

			<>
				<Footer />
			</>

		</ThemeProvider>
	)
}

export default BlogPage;

export const pageQuery = graphql`
    query {
        allImageSharp(filter: {original: {src: {regex: "/infonutrientes/"}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;
